@import 'variables';
@import 'mixins';
@import 'theme';
@import 'icons';
@import 'animations';
@import 'fireworks';

// Glide Carousel
@import 'glide.core';

// React Datepicker
@import 'react-datepicker.css';

// React Toastify
@import 'ReactToastify.css';

// React Confirm Alert
@import 'react-confirm-alert.css';
@import 'confirmAlert'; // Custom overrides

// Plyr
@import 'plyr.css';

// Tippy
@import 'tippy.css';

html,
body {
	max-width: 100vw;
	overflow-x: hidden;
	font-optical-sizing: auto;
	font-style: normal;
}

body {
	display: grid;
	grid-template-rows: auto 1fr auto;
	min-height: 100vh;
	padding: 0;
	margin: 0;

	font-family: var(--default-font);
	font-weight: 500;
	font-size: var(--default-font-size);
	color: var(--primary-color);

	// Background
	background-color: var(--primary-bg-color);
	background-image: var(--primary-bg-image);
	background-size: var(--primary-bg-size);

	// Scroll behavior
	scroll-behavior: smooth;

	&:focus-within {
		scroll-behavior: smooth;
	}

	// Background transition
	transition: background 0.2s linear;
}

// To avoid flickering when loading fonts
.LoadingFonts {
	visibility: hidden;
}

.App {

	.Private,
	.Public {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr auto;
		grid-template-areas: 'header' 'content' 'footer';
	}
}

a {
	color: var(--link-color);
	text-decoration: none;

	&:hover {
		color: var(--link-color-hover);
		text-decoration: underline;
	}
}

// Default box-sizing
*,
*:before,
*:after {
	box-sizing: border-box;
}

// Remove webkit auto-fill background color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

// Flag sizes
.Flag {
	display: block;

	&--1x1 {
		width: 20px;
		height: 20px;
	}

	&--4x3 {
		width: 27px;
		height: 20px;
	}
}