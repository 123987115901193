.Articles {
	padding: 20px;

	&_Bar {
		display: grid;
		justify-content: right;
	}

	&_List {
		display: grid;
		grid-auto-flow: row;
		grid-gap: 10px;

		&_Info {
			display: grid;
			grid-auto-flow: column;
			grid-gap: 10px;
			padding: 10px;
			font-size: 12px;
		}

		&_Table {
			text-align: left;
			width: 100%;
			background-color: #f2c6d3;

			th {
				padding: 10px;
			}

			.Articles_List_Date .Icon {
				margin-left: 10px;
				cursor: pointer;
			}
		}

		&_Body {
			th {
				font-weight: normal;
			}

			&:nth-child(even) {
				background-color: #f5f5f5;
			}

			&:nth-child(odd) {
				background-color: #fff;
			}

			&_Row {
				&:hover {
					background-color: var(--highlight-color);
					color: #fff;
					cursor: pointer;
				}

				.Articles_List_Title {
					max-width: 200px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			}
		}

		&_Title {
			width: 200px;
		}

		&_Date {
			width: 100px;
		}

		&_LastUpdate {
			width: 100px;
		}

		&_Status {
			width: 100px;
		}

		&_Language {
			width: 100px;
		}
	}

	&_NoRecordFound {
		text-align: center;
	}
}
