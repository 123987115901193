.EditArticleSettings {
	padding: 20px;
	font-size: 1em;
	max-width: 1024px;

	.Admin_H1 {
		margin-bottom: 20px;
	}

	.FormFieldSet {
		margin: 20px 0;
	}

	&_BulkOperations {
		border-color: #333333;

		legend {
			color: #333333;
			padding: 0 10px;
			font-weight: 600;
		}
	}

	&_DangerZone {
		border-color: var(--error-color);

		legend {
			color: var(--error-color);
			padding: 0 10px;
			font-weight: 600;
		}
	}

	.BackLink {
		margin-bottom: 20px;
	}

	.TabLinkNavigation {
		margin-top: 20px;
	}
}
