// Theme
:root {
	// Background
	--primary-bg-color: #fff;
	--primary-bg-image: radial-gradient(
		circle,
		rgba(228, 229, 253, 1) 10%,
		rgba(252, 243, 247, 0.8897934173669468) 40%,
		rgba(221, 243, 222, 0.6601015406162465) 90%,
		#fed4e7 100%
	);

	--primary-bg-size: 100% 100%;
	--secondary-bg: #fcf3f7;

	// Text
	--primary-color: #0f3652;
	--highlight-color: #ff3974;

	// Navigation
	--navigation-link-hover-bg: #FCE0EA;
	--navigation-link-active-bg: #FCE0EA;
	--navigation-mobile-bottom-border: 1px solid rgba(0, 0, 0, 0.1);
	--navigation-mobile-separator-color: rgba(0, 0, 0, 0.1);

	// Containers
	--border-radius: 8px;
	--tag-border-radius: 6px;

	// Links
	--link-color: #0f3652;
	--link-color-hover: #ff3974;

	// Spinner
	--spinner-color: #ff3974;

	// Color coding
	--info-color: #5292da;
	--info-light: #d2e7fd;

	--success-color: #4bb763;
	--success-light: #d2fadb;

	--warning-color: #f7a936;
	--warning-light: #fcecd4;

	--error-color: #e35454;
	--error-light: #ffe4e4;

	// Loading Dots
	--loading-dot-color: #ff3974;
	--loading-dot-color-light: #fba7c0;

	// Scrollbar
	--scrollbar-width: 0.5em;
	--scrollbar-height: 0.5em;
	--scrollbar-bg: #f0f0f0;
	--scrollbar-border-radius: 0;
	--scrollbar-track: inset 0 0 6px #e0e0e0;
	--scrollbar-thumb-bg: #e0e0e0;
	--scrollbar-thumb-border: 1px #e0e0e0 solid;
	--scrollbar-thumb-radius: 0;
	--scrollbar-thumb-top-width: 2px;
	--scrollbar-thumb-bottom-width: 2px;
	--scrollbar-hover-bg: #f0f0f0;
	--scrollbar-hover-border: 1px #f0f0f0 solid;

	// Font
	--default-font: 'Noto Sans', Arial, sans-serif; // Default font dynamically overwrite in the layout.tsx
	--default-font-size: 18px;
	--default-mobile-font-size: 16px;
	--default-Icon-font: 'Material Symbols Outlined';

	// Form elements
	--form-element-border: 1px solid rgba(0, 0, 0, 0.3);
	--form-button-border-radius: 100px;

	// Defaults
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// Button
	--button-sm-padding: 0 16px;
	--button-sm-font-size: 12px;
	--button-sm-border-radius: 100px;
	--button-sm-height: 36px;
	--button-sm-icon-size: 18px;

	--button-md-padding: 18px 32px;
	--button-md-font-size: 16px;
	--button-md-border-radius: 100px;
	--button-md-height: 55px;
	--button-md-icon-size: 26px;

	--button-border-width: 1px;

	// Button Dark Blue
	--button-dark-blue-bg: #0F3652;
	--button-dark-blue-bg-hover: #FF3A74;
	--button-dark-blue-bg-disabled: #0f365287;
	--button-dark-blue-color: #FFF;
	--button-dark-blue-color-hover: #FFF;
	--button-dark-blue-color-disabled: #ffffff87;
	--button-dark-blue-border-color: #0F3652;
	--button-dark-blue-border-color-hover: #FF3A74;
	--button-dark-blue-border-color-disabled: #ffffff87;

	// Button Light
	--button-light-bg: transparent;
	--button-light-bg-hover: #FFF;
	--button-light-bg-disabled: transparent;
	--button-light-color: #0F3652;
	--button-light-color-hover: #FF3A74;
	--button-light-color-disabled: rgba(0, 0, 0, 0.2);
	--button-light-border-color: #CDCDD6;
	--button-light-border-color-hover: #CDCDD6;
	--button-light-border-color-disabled: rgba(0, 0, 0, 0.2);

	// Inputs
	--input-icon-color: #8594A4;
	--input-placeholder-color: #8594A4;
	--input-border-radius: 8px;
	--input-box-shadow: 0px 2px 3px 0px #00000017;
	--input-background: #fff;
	--input-border-size: 1px;
	--input-border-color: #fff;
	--input-height: 44px;
	--input-font-size: 16px;
	--input-padding: 9px 18px;
	--input-error-font-size: 14px;

	// Scroll behaviour
	scroll-behavior: smooth;

	&:focus-within {
		scroll-behavior: smooth;
	}
}

// [data-theme='dark'] {}

// // Responsive tablet
// @include minTablet {
// 	:root {}
// }

// // Responsive desktop
// @include minDesktop {
// 	:root {}
// }

[data-theme='dark'] [data-hide-on-theme='dark'],
[data-theme='light'] [data-hide-on-theme='light'] {
	display: none;
}