@import '../../../assets/mixins';

.H4 {
	font-weight: 600;
	font-size: 18px;
	line-height: 28.8px;
	color: var(--primary-color);

	@include minDesktop {
		font-weight: 700;
		font-size: 20px;
		line-height: 28px;
	}

	&--centered {
		text-align: center;
	}
}
