.Switch {
	display: grid;
	grid-auto-flow: row;

	&_Label {
		display: none;
		grid-auto-flow: column;
		justify-content: start;
		font-size: 18px;
		margin-bottom: 10px;
		gap: 6px;
		justify-items: start;
		align-items: center;

		.MaterialSymbol {
			color: var(--input-icon-color);
			font-size: 18px;
			line-height: 18px;
		}

		label {
			color: var(--primary-color);
			white-space: nowrap;
			font-size: 16px;
			font-weight: 400;
		}

		&_Help {
			cursor: help;
			justify-self: end;

			&:hover {
				color: var(--highlight-color);
			}
		}
	}

	&--hasLabel {
		.Switch_Label {
			display: grid;
		}
	}

	// Switch Wrapper
	&_Wrapper {
		display: grid;
		grid-auto-flow: column;
		justify-content: left;
		align-items: center;
		grid-gap: 8px;

		&_Label {
			font-size: 16px;
		}
	}

	// The slider
	&_Slider {
		position: relative;
		display: inline-block;
		width: 45px;
		height: 28px;

		&_Slide {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #dedede;
			transition: 0.4s;
			border-radius: 100px;

			&::before {
				position: absolute;
				content: '';
				height: 24px;
				width: 24px;
				background-color: #ffffff;
				transition: 0.4s;
				border-radius: 100px;
				margin: 2px 0 0 2px;
			}
		}
	}

	&--checked {
		.Switch_Slider_Slide {
			background-color: var(--highlight-color);

			&::before {
				transform: translateX(17px);
			}
		}
	}
}
