.EditTheory {
	padding: 20px;
	font-size: 1em;
	max-width: 1024px;

	.Admin_H1 {
		margin-bottom: 20px;
	}

	.BackLink {
		margin-bottom: 20px;
	}

	.TabLinkNavigation {
		margin: 20px 0;
	}

	&_FeatureAndSlug {
		display: grid;
		grid-template-columns: 200px 200px 1fr;
		grid-gap: 20px;
	}

	&_Form {
		margin-top: 20px;

		&_Icon {
			display: grid;
			grid-template-columns: 1fr 100px;
		}
	}
}
