@import '../../../assets/mixins.scss';

.FormGroup {
	margin-bottom: 24px;

	&--hidden {
		display: none;
	}

	@include minDesktop {
		margin-bottom: 32px;
	}

	&--twoColumn {
		display: grid;
		gap: 16px;
		align-items: flex-start;

		@include minDesktop {
			grid-template-columns: 1fr 1fr;
		}
	}

	&--threeColumn {
		display: grid;
		gap: 16px;
		align-items: flex-start;

		@include minDesktop {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	&--fourColumn {
		display: grid;
		gap: 16px;
		align-items: flex-start;

		@include minDesktop {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}

	&--fiveColumn {
		display: grid;
		gap: 16px;
		align-items: flex-start;

		@include minDesktop {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		}
	}
}

.FormFieldSet {
	margin-bottom: 24px;
	border: 1px solid #dedede;

	legend {
		padding: 8px;
	}

	@include minDesktop {
		margin-bottom: 32px;
	}
}
