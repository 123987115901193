@import '../../../assets/mixins';

$iconSize: 1.5em;
$gap: 1.5em;

.ContentBox {
	padding: 16px;
	border-radius: 8px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.02);
	background: #ffffff;
	color: #000000;
	margin-bottom: 24px;

	@include minDesktop {
		margin-bottom: 32px;
	}

	&_Header {
		display: grid;
		grid-template-columns: 1fr;
		column-gap: $gap;
		align-items: center;
		cursor: pointer;

		i {
			font-size: $iconSize;
		}

		h4 {
			margin: 0;
			padding: 0;
		}
	}

	&--hasIcon .ContentBox_Header {
		grid-template-columns: $iconSize 1fr;
	}

	&--open .ContentBox_Header,
	&--collapsed .ContentBox_Header {
		grid-template-columns: 1fr $iconSize;
	}

	&--hasIcon.ContentBox--collapsed .ContentBox_Header {
		grid-template-columns: $iconSize 1fr $iconSize;
	}

	&--collapsed .ContentBox_Content {
		opacity: 0;
		transform: translateY(-50%);
		visibility: hidden;
		transition:
			opacity 0.1s ease,
			transform 0.1s ease,
			visibility 0.1s ease;
		height: 0;
		overflow: hidden;
		padding-top: 0;
	}

	&_Content {
		padding-top: $gap;
		opacity: 1;
		margin: 0;
		transform: translateY(0);
		visibility: visible;
		transition:
			opacity 0.1s ease,
			transform 0.1s ease,
			visibility 0.1s ease;
	}
}
