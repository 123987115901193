@import '../../../assets/mixins';

.P {
	font-weight: 400;
	font-size: 16px;
	line-height: 25.6px;

	&--md-bold {
		font-weight: 700;
	}

	&--lg {
		font-size: 18px;
		line-height: 28.8px;

		@include minDesktop {
			font-size: 22px;
			line-height: 30.8px;
		}
	}

	&--sm {
		font-weight: 500;
		font-size: 14px;
		line-height: 22.4px;
	}
}