@import '../../../assets/mixins';

.H1 {
	font-weight: 700;
	font-size: 26px;
	line-height: 36.4px;
	color: var(--primary-color);

	@include minDesktop {
		font-size: 48px;
		line-height: 57.6px;
	}

	&--centered {
		text-align: center;
	}
}
