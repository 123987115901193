.PublicHeader {
	display: grid;
	grid-template-columns: min-content 1fr max-content;
	align-items: center;
	padding: 20px;
	text-align: center;

	&_Login {
		display: grid;
		grid-auto-flow: column;
		justify-self: right;
		column-gap: 8px;
		align-items: center;
	}
}
