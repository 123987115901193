.EditArticleImages {
	padding: 20px;
	font-size: 1em;
	max-width: 1024px;

	.Admin_H1 {
		margin-bottom: 20px;
	}

	.FormFieldSet {
		margin: 20px 0;
	}

	.BackLink {
		margin-bottom: 20px;
	}

	.TabLinkNavigation {
		margin-top: 20px;
	}

	&_Images {
		& > div {
			position: relative;

			span {
				position: absolute;
				top: 0;
				left: 0;
				padding: 5px;
				background: #dedede;
				border-radius: 0 0 5px 0;
			}
		}
	}
}
