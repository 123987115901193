.ProgressBar {
	display: block;
	width: '100%';
	background-color: #c1c1c1;
	height: 20px;
	position: relative;

	&_Progress {
		background-color: var(--info-color);
		height: 100%;
	}

	&_Percentage {
		position: absolute;
		font-size: 12px;
		color: #dedede;
		left: 3px;
		top: 3px;
	}
}
