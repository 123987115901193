// Input Container
.Checkbox {
	display: grid;
	height: 22px;
	line-height: 18px;
	grid-auto-flow: column;
	align-items: center;
	justify-content: left;
	gap: 8px;
	cursor: pointer;

	&_Icon {
		&--unchecked {
			display: block;
		}

		&--checked {
			display: none;
		}
	}

	&_Input {
		display: none;

		&:checked {
			&~.Checkbox_Label {

				.Checkbox_Icon--unchecked {
					display: none;
				}

				.Checkbox_Icon--checked {
					display: block;
				}
			}
		}
	}

	&_Label {
		display: grid;
		grid-auto-flow: column;
		justify-content: start;
		gap: 8px;
		align-items: center;
		color: var(--primary-color);
		font-size: 14px;
		padding: 0;
		margin: 0;
		cursor: pointer;
	}
}