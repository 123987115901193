@import 'variables';
@import 'icons';

// Min Desktop
@mixin minDesktop {
	@media only screen and (min-width: #{$desktop-breakpoint + 1}) {
		@content;
	}
}

// Min Desktop
@mixin minTablet {
	@media only screen and (min-width: #{$tablet-breakpoint + 1}) {
		@content;
	}
}

// Min Mobile
@mixin minMobile {
	@media only screen and (min-width: #{$mobile-breakpoint + 1}) {
		@content;
	}
}

@mixin inputIcons {
	@each $name, $unicode in $input-icon-list {
		&[icon='#{$name}'] .Input_Wrapper::before {
			content: #{$unicode};
		}
	}
}

@mixin textareaIcons {
	@each $name, $unicode in $input-icon-list {
		&[icon='#{$name}'] .TextArea_Wrapper::before {
			content: #{$unicode};
		}
	}
}

@mixin selectIcons {
	@each $name, $unicode in $input-icon-list {
		&[icon='#{$name}']::before {
			content: #{$unicode};
		}
	}
}

@mixin buttonIcons {
	@each $name, $unicode in $button-icon-list {
		&[icon='#{$name}']::before {
			content: #{$unicode};
		}
	}
}

// Grab Cursor
@mixin cursorGrab {
	cursor: move;
	/* fallback if grab cursor is unsupported */
	cursor: grab;

	&:active {
		cursor: grabbing;
	}
}

@mixin addBubbles {

	// Bubbles animation
	&::before {
		position: absolute;
		content: '';
		left: 30%;
		top: 5%;
		width: 100%;
		height: 100%;
		max-width: 450px;
		max-height: 450px;
		background: #f0b3ff40;
		border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
		will-change: border-radius, transform, opacity;
		animation: bubblesAnimation 5s linear infinite;
		display: block;
		z-index: -1;
		-webkit-animation: bubblesAnimation 5s linear infinite;
	}

	&::after {
		position: absolute;
		content: '';
		left: 10%;
		top: 0;
		width: 100%;
		height: 100%;
		max-width: 350px;
		max-height: 350px;
		background: #f2c6d361;
		border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
		will-change: border-radius, transform, opacity;
		animation: bubblesAnimation 4s linear infinite;
		display: block;
		z-index: -2;
		-webkit-animation: bubblesAnimation 4s linear infinite;
	}
}

@mixin scrollBarStyle {
	@include minDesktop {
		&::-webkit-scrollbar {
			width: var(--scrollbar-width);
			height: var(--scrollbar-height);
			background: var(--scrollbar-bg);
			border-radius: var(--scrollbar-border-radius);
		}

		&::-webkit-scrollbar-track {
			box-shadow: var(--scrollbar-track);
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--scrollbar-thumb-bg);
			border: var(--scrollbar-thumb-border);
			border-radius: var(--scrollbar-thumb-radius);
			border-top-width: var(--scrollbar-thumb-top-width);
			border-bottom-width: var(--scrollbar-thumb-bottom-width);

			&:hover {
				background-color: var(--scrollbar-hover-bg);
				border: var(--scrollbar-hover-border);
			}
		}
	}
}