.GenerateArticle {
	display: grid;
	grid-template-rows: 80px 1fr;
	width: 100%;
	height: 100%;
	border: 1px #ccc solid;
	background-color: #fff;
	font-size: 14px;
	position: relative;

	&_Header {
		display: grid;
		align-items: center;
		grid-auto-flow: column;
		justify-content: space-between;
		border-bottom: 1px #ccc solid;
		padding: 10px;

		h1 {
			padding: 0;
			margin: 0;
		}

		.Icon {
			cursor: pointer;
			font-size: 34px;

			&:hover {
				color: #ff3974;
			}
		}
	}

	&_Body {
		display: grid;
		grid-template-rows: 1fr max-content;
		padding: 10px;
		overflow: auto;
	}

	&_Template {
		.TextArea_Wrapper textarea {
			background-color: #000;
			color: #fff;
			min-height: 300px;
		}
	}

	&_Prompt {
		.TextArea_Wrapper textarea {
			min-height: 100px;
		}
	}
}
