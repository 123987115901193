.HistoryBackLink {
	display: grid;
	grid-auto-flow: column;
	gap: 6px;
	justify-content: start;
	align-items: center;
	cursor: pointer;

	.MaterialSymbol {
		font-size: 18px;
	}

	span {
		color: var(---primary-color);
		font-size: 16px;
	}

	&:hover {
		.MaterialSymbol {
			color: var(--highlight-color);
		}

		span {
			color: var(--highlight-color);
		}
	}
}