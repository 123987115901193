.Loading {
	display: grid;
	align-items: center;
	justify-items: center;
	color: var(--spinner-color);

	i {
		font-family: var(--default-Icon-font);
		display: inline-block;
		width: 20px;
		height: 20px;
		font-size: 20px;
		line-height: 20px;
		max-width: 20px;
		font-weight: 900;
	}

	&--white {
		color: #ffffff;
	}

	&--black {
		color: #000000;
	}
}
