@import '../../../assets/mixins';
@import '../../../assets/variables';

// Select Container
.Select {
	display: grid;
	position: relative;
	cursor: pointer;

	&:focus-within {
		&::before {
			color: #1e1e1e;
		}
	}

	// Label
	label {
		color: #757575;
		font-size: $md-input-label-font-size;
		letter-spacing: 0.4px;
		margin-bottom: 8px;
	}

	&.Select--hasLabel::before {
		top: calc(15px + 50%);
	}

	// Invalid Message Feedback
	&_InvalidMessage {
		color: var(--error-color);
		margin: 8px 0 0 0;

		// Medium - Defaults
		font-size: $md-input-label-font-size;
	}

	// Default
	select {
		background-color: #ffffff;
		color: #1e1e1e;
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
		width: 100%;
		max-width: 100%;
		cursor: pointer;

		&:focus {
			outline: $md-input-border-size solid var(--info-color);
			outline-offset: -$md-input-border-size;
		}

		// Medium - Default
		height: $md-input-height;
		border: $md-border;
		border-radius: $md-border-radius;
		font-size: $md-input-font-size;
		padding: $md-input-padding;
		padding-right: 40px;

		// Down Arrow
		appearance: none;
		background-image: url(https://cdn.ruya.co/cdn/assets/svg/selectDownIcon.svg);
		background-repeat: no-repeat;
		background-position: center right;
		background-size: $md-input-icon-size;
		background-position-x: calc(100% - 16px);

		// Focus
		&:focus {
			border-color: rgba(82, 146, 218, 0.722);
			color: #1e1e1e;
			background-image: url(https://cdn.ruya.co/cdn/assets/svg/selectDownIconHover.svg);
		}

		// Disabled
		&:disabled {
			background-color: #dedede;
			color: #9b9b9b;
		}
	}

	&_ResetIcon {
		position: absolute;
		right: 55px;
		bottom: 18px;
		font-size: $md-input-icon-size;
		color: #4f4f4f;
	}

	// ----- ICON - START -----
	&[icon] {
		color: #1e1e1e;

		select {
			padding-left: 38px;
		}

		&::before {
			display: block;
		}
	}

	// Pre-defined icon list
	@include selectIcons;

	// Default
	&::before {
		display: none;
		position: absolute;
		font-family: var(--default-Icon-font);
		font-weight: 900;
		line-height: 100%;
		content: '\f246'; // Default Icon
		top: 50%;
		transform: translateY(-50%);
		left: 10px;
		font-size: $sm-input-icon-size;
		color: #9b9b9b;
	}

	// Small
	&--xs {
		label {
			font-size: $xs-input-label-font-size;
		}

		select {
			height: $xs-input-height;
			padding: $xs-input-padding;
			padding-right: 40px;
			font-size: $xs-input-font-size;
			background-size: $xs-input-icon-size;
			border: $xs-border;

			&:focus {
				outline: $xs-input-border-size solid var(--info-color);
				outline-offset: -$xs-input-border-size;
			}
		}

		.Select_InvalidMessage {
			font-size: $xs-input-label-font-size;
		}

		&[icon] select {
			padding-left: 38px;
		}

		.Select_ResetIcon {
			bottom: 10px;
			right: 50px;
			font-size: $xs-input-icon-size;
		}
	}

	// Small
	&--sm {
		label {
			font-size: $sm-input-label-font-size;
		}

		select {
			height: $sm-input-height;
			padding: $sm-input-padding;
			padding-right: 40px;
			font-size: $sm-input-font-size;
			background-size: $sm-input-icon-size;
			border: $sm-border;

			&:focus {
				outline: $sm-input-border-size solid var(--info-color);
				outline-offset: -$sm-input-border-size;
			}
		}

		.Select_InvalidMessage {
			font-size: $sm-input-label-font-size;
		}

		&[icon] select {
			padding-left: 38px;
		}

		.Select_ResetIcon {
			bottom: 10px;
			right: 50px;
			font-size: $sm-input-icon-size;
		}
	}

	// Large
	&--lg {
		label {
			font-size: $lg-input-label-font-size;
		}

		select {
			height: $lg-input-height;
			font-size: $lg-input-font-size;
			padding: $lg-input-padding;
			padding-right: 40px;
			background-size: $lg-input-icon-size;

			&:focus {
				outline: $lg-input-border-size solid var(--info-color);
				outline-offset: -$lg-input-border-size;
			}
		}

		.Select_InvalidMessage {
			font-size: $lg-input-label-font-size;
		}

		&[icon] select {
			padding-left: 38px;
		}

		.Select_ResetIcon {
			bottom: 26px;
			right: 60px;
			font-size: $lg-input-icon-size;
		}
	}

	// ----- ICON - END -----

	// Disabled
	&--disabled {
		&::before {
			color: #9b9b9b;
		}
	}

	// Warning
	&--warning {
		label {
			color: var(--warning-color);
		}

		select {
			border-color: var(--warning-color);
		}
	}

	// Invalid
	&--invalid {
		label {
			color: var(--error-color);
		}

		select {
			border-color: var(--error-color);
		}
	}

	// Valid
	&--valid {
		label {
			color: var(--success-color);
		}

		select {
			border-color: var(--success-color);
		}
	}

	// Placeholder color
	&--placeholderSelected select {
		color: #9b9b9b;

		&:focus {
			color: unset;
		}
	}

	// Multiple line
	select[multiple],
	select[size] {
		background-image: none;
		background-color: #ffffff;
		height: unset !important;
	}

	// UX: Valid state is disabled because of misleading user.
	.was-validated :valid,
	.is-valid {
		background-image: none;
	}
}