.TabLinkNavigation {
	border-bottom: 1px solid #ff3974;

	ul {
		display: grid;
		grid-auto-flow: column;
		gap: 1px;
		justify-content: left;
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			padding: 0;
			margin: 0;
		}
	}

	.ReactLink {
		display: block;
		padding: 10px;
		background: #fff;
		text-decoration: none;
		color: #19395c;

		&:hover {
			background: #f2c6d3;
		}

		&--active {
			background: #ff3974;
			color: #ffffff;

			&:hover {
				background: #ff3974;
			}
		}
	}
}
