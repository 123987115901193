.MaterialSymbol {
	font-family: 'Material Symbols Outlined';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
	font-feature-settings: 'liga'; // Define the standard property 'font-feature-settings' for compatibility
	transition: font-variation-settings 1s ease; // Smooth transition for the hover effect
}