.FileInput {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 1fr max-content;
	gap: 16px;

	// Icon
	&--hasIcon {
		grid-template-columns: 24px 1fr max-content;
	}

	&_Icon {
		color: #000000;

		.Icon {
			font-size: 24px;
		}
	}

	// Label and file list
	&_Info {
		display: grid;
		grid-auto-flow: row;
		gap: 12px;
	}

	// Explanation label
	&_Label {
		font-size: 16px;
		color: #000000;
		font-family: var(--Regular);

		.Icon {
			margin-left: 8px;
		}
	}

	// Selected file list
	&_Files {
		display: grid;
		gap: 2px;
		font-size: 12px;
		color: #757575;
		font-family: var(--Regular);

		span {
			display: block;
		}
	}

	// Select button and input
	&_Button {
		display: grid;
	}

	// Select button text
	&_ButtonText {
		display: grid;
		height: max-content;
		padding: 12px 24px;
		font-size: 12px;
		color: #4f4f4f;
		font-weight: 600;
		background-color: #dedede;

		&:hover {
			cursor: pointer;
		}
	}

	// Hide input
	&_Input {
		display: none;
	}

	// Size small
	&--sm {
		grid-template-columns: 1fr max-content;
		gap: 8px;

		// Icon
		&.FileInput--hasIcon {
			grid-template-columns: 16px 1fr max-content;
		}

		// Icon
		.FileInput_Icon .Icon {
			font-size: 16px;
		}

		// Explanation label
		.FileInput_Label {
			font-size: 14px;
		}

		// Selected file list
		.FileInput_Files {
			font-size: 10px;
		}

		// Select button text
		.FileInput_ButtonText {
			padding: 4px 8px;
			font-size: 12px;
		}
	}

	// Size large
	&--lg {
		grid-template-columns: 1fr max-content;
		gap: 16px;

		// Icon
		&.FileInput--hasIcon {
			grid-template-columns: 24px 1fr max-content;
		}

		// Icon
		.FileInput_Icon .Icon {
			font-size: 28px;
		}

		// Explanation label
		.FileInput_Label {
			font-size: 18px;
		}

		// Selected file list
		.FileInput_Files {
			font-size: 14px;
		}

		// Select button text
		.FileInput_ButtonText {
			padding: 20px 32px;
			font-size: 16px;
		}
	}

	// Warning
	&--warning {
		// Icon
		.FileInput_Icon {
			color: var(--warning-color);
		}

		// Explanation label
		.FileInput_Label {
			color: var(--warning-color);
		}

		// Select button text
		.FileInput_ButtonText {
			color: #fff;
			background-color: var(--warning-color);
		}
	}

	// Invalid
	&--invalid {
		// Icon
		.FileInput_Icon {
			color: var(--error-color);
		}

		// Explanation label
		.FileInput_Label {
			color: var(--error-color);
		}

		// Select button text
		.FileInput_ButtonText {
			color: #fff;
			background-color: var(--error-color);
		}
	}

	// Disabled
	&--disabled {
		// Icon
		.FileInput_Icon {
			color: #f2f2f2;
		}

		// Explanation label
		.FileInput_Label {
			color: #c1c1c1;
		}

		// Selected file list
		.FileInput_Files {
			color: #c1c1c1;
		}

		// Select button text
		.FileInput_ButtonText {
			color: #c1c1c1;
			background-color: #f2f2f2;
		}
	}

	// Loading
	&--loading {
	}
}
