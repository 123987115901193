// Breakpoints
$desktop-breakpoint: 1200px;
$tablet-breakpoint: 992px;
$mobile-breakpoint: 768px;

// Content Width
$content-max-width: 1440px;

$header-padding-mobile: 18px;
$header-padding: 36px 120px;

$content-padding-mobile: 18px;
$content-padding: 36px 120px;

// TODO: Below variables should refactor with css variables

// Border
$xs-border: 0;
$sm-border: 1px solid rgba(0, 0, 0, 0.14);
$md-border: 2px solid rgba(0, 0, 0, 0.14);
$lg-border: 2px solid rgba(0, 0, 0, 0.14);

// Border radius
$xs-border-radius: 0;
$sm-border-radius: 0;
$md-border-radius: 0;
$lg-border-radius: 0;

// Box Shadow
$sm-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
$md-box-shadow:
	0px 1px 0px rgba(0, 0, 0, 0.04),
	0px 2px 8px rgba(0, 0, 0, 0.04);
$lg-box-shadow:
	0px 1px 0px rgba(0, 0, 0, 0.04),
	0px 2px 8px rgba(0, 0, 0, 0.04);

$sm-box-shadow-focus: 0px 1px 4px rgba(82, 146, 218, 0.16);
$md-box-shadow-focus:
	0px 1px 0px rgba(82, 146, 218, 0.04),
	0px 2px 8px rgba(82, 146, 218, 0.24);
$lg-box-shadow-focus:
	0px 1px 0px rgba(82, 146, 218, 0.04),
	0px 2px 8px rgba(82, 146, 218, 0.24);

// Buttons
$sm-button-height: 24px;
$md-button-height: 36px;
$lg-button-height: 60px;

$sm-button-font-size: 12px;
$md-button-font-size: 12px;
$lg-button-font-size: 16px;

$sm-button-icon-size: 12px;
$md-button-icon-size: 16px;
$lg-button-icon-size: 20px;

$sm-button-border-radius: 0;
$md-button-border-radius: 0;
$lg-button-border-radius: 0;

$sm-button-padding: 4px 8px;
$md-button-padding: 12px 24px;
$lg-button-padding: 20px 32px;

// Inputs
$xs-input-height: 24px;
$sm-input-height: 36px;
$md-input-height: 56px;
$lg-input-height: 76px;

$xs-input-font-size: 12px;
$sm-input-font-size: 14px;
$md-input-font-size: 16px;
$lg-input-font-size: 20px;

$xs-input-border-size: 1px;
$sm-input-border-size: 1px;
$md-input-border-size: 2px;
$lg-input-border-size: 2px;

$xs-input-icon-size: 14px;
$sm-input-icon-size: 16px;
$md-input-icon-size: 20px;
$lg-input-icon-size: 24px;

$xs-input-label-font-size: 10px;
$sm-input-label-font-size: 12px;
$md-input-label-font-size: 14px;
$lg-input-label-font-size: 16px;

$xs-input-padding: 4px 6px;
$sm-input-padding: 4px 8px;
$md-input-padding: 6px 12px;
$lg-input-padding: 8px 16px;

$xs-input-padding-copy: 4px 30px 4px 4px;
$sm-input-padding-copy: 4px 36px 4px 8px;
$md-input-padding-copy: 10px 44px 6px 12px;
$lg-input-padding-copy: 8px 48px 8px 16px;

$xs-textarea-padding: 0 10px;
$sm-textarea-padding: 6px 8px;
$md-textarea-padding: 14px 12px;
$lg-textarea-padding: 20px 16px;

$xs-textarea-padding-copy: 0 30px 4px 4px;
$sm-textarea-padding-copy: 6px 36px 4px 8px;
$md-textarea-padding-copy: 14px 44px 6px 12px;
$lg-textarea-padding-copy: 20px 48px 8px 16px;

$xs-input-icon-padding-left: 30px;
$sm-input-icon-padding-left: 36px;
$md-input-icon-padding-left: 48px;
$lg-input-icon-padding-left: 58px;

// Labels
$sm-label-font-size: 12px;
$md-label-font-size: 14px;
$lg-label-font-size: 16px;

// Z Index
$-z-index-modal: 70;
$-z-index-change-language: 60;
$-z-index-dateSelector: 20;
