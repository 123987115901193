.InfoBox {
	position: relative;
	display: grid;
	align-items: center;
	padding: 16px;
	border-radius: var(--border-radius);
	gap: 8px;

	// Header
	.H4 {
		font-family: var(--Bold);
		padding: 0;
		margin: 0;
		font-size: 14px;
		margin-bottom: 8px;
		color: var(--primary-color);
		display: grid;
		grid-template-columns: auto 1fr;
		gap: 8px;
		align-items: center;
	}

	// Text
	.P {
		font-family: var(--Regular);
		padding: 0;
		margin: 0;
		font-size: 14px;
		color: var(--primary-color);
		line-height: 24px;
	}

	// Default : Info
	&[type='info'] {
		background-color: var(--info-light);

		.MaterialSymbol {
			color: var(--info-color);
		}
	}

	// Success
	&[type='success'] {
		background-color: var(--success-light);

		.MaterialSymbol {
			color: var(--success-color);
		}
	}

	// Warning
	&[type='warning'] {
		background-color: var(--warning-light);

		.MaterialSymbol {
			color: var(--warning-color);
		}
	}

	// Error
	&[type='error'] {
		background-color: var(--error-light);

		.MaterialSymbol {
			color: var(--error-color);
		}
	}

	// Highlight
	&[type='highlight'] {
		background-color: #fff;

		.MaterialSymbol {
			color: var(--highlight-color);
		}
	}
}