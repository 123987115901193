.AdminNav {
	display: grid;
	grid-template-rows: 75px 1fr;
	padding: 20px;
	background-color: #ffffff;
	color: #0f3652;
	border-right: 1px solid #f5f6f7;
	border-bottom: 1px solid #f5f6f7;

	&_Nav {
		position: fixed;
		top: 0;
		display: grid;
		grid-auto-flow: row;
		justify-content: space-between;
		height: 100vh;

		ul {
			list-style: none;
			padding: 0;
			margin: 20px 0;
			width: 160px;

			li {
				margin: 0;
				padding: 5px 0;

				.NavigationLink {
					display: block;
					padding: 6px 12px;
					border-radius: 8px;
					color: var(--link-color);
					cursor: pointer;
					text-decoration: none;
					font-size: 14px;
					font-weight: 600;
					line-height: 20px;

					&:hover {
						color: var(--link-color-hover);
						background-color: #fcf3f7;
					}

					&--active {
						color: var(--link-color-hover);
						background-color: #f2c6d3;

						&:hover {
							background-color: #f2c6d3;
						}
					}
				}
			}
		}

		&_Bottom {
			align-self: end;
		}

		&_Logo {
			padding-bottom: 30px !important;
			border-bottom: 1px solid #f5f6f7;
		}
	}
}
