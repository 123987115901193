.Login {
	padding: 0 20px;
	display: grid;
	margin: 20px;

	&_Form {
		display: grid;
		grid-auto-flow: row;
		gap: 8px;
		width: 100%;
		max-width: 600px;
		justify-content: start;
		align-content: start;
	}

	&_Error {
		color: var(--error-color);
		margin: 0;
	}

	&_CaptchaError {
		color: var(--warning-color);
		margin: 0;
	}
}
