@import '../../../assets/mixins';
@import '../../../assets/variables';

.Button {
	// Base styles
	display: grid;
	grid-auto-flow: column;
	gap: 1em;
	white-space: nowrap;
	border: 0;
	font-weight: 500;
	align-items: center;
	align-content: center;
	justify-items: center;
	justify-content: center;
	cursor: pointer;
	line-height: 0;
	box-sizing: border-box;

	// Size
	font-size: var(--button-md-font-size);
	border-radius: var(--button-md-border-radius);
	height: var(--button-md-height);
	padding: var(--button-md-padding);

	.MaterialSymbol {
		font-size: var(--button-md-icon-size);
	}

	// Border
	border-width: var(--button-border-width);
	border-style: solid;

	// Colors
	background-color: var(--button-dark-blue-bg);
	border-color: var(--button-dark-blue-border-color);
	color: var(--button-dark-blue-color);

	// Hover and Focus
	&:hover,
	&:focus {
		background-color: var(--button-dark-blue-bg-hover);
		color: var(--button-dark-blue-color-hover);
		border-color: var(--button-dark-blue-border-color-hover);
	}

	&:focus {
		outline: 0;
	}

	// Block
	&--block {
		width: 100%;
	}

	// Disabled State
	&:disabled,
	&[disabled] {
		cursor: not-allowed;
		background-color: var(--button-dark-blue-bg-disabled);
		color: var(--button-dark-blue-color-disabled);
		border-color: var(--button-dark-blue-border-color-disabled);

		&:hover {
			background-color: var(--button-dark-blue-bg-disabled);
			color: var(--button-dark-blue-color-disabled);
		}
	}

	&--loading {
		cursor: progress;
		background-color: var(--button-dark-blue-bg-hover);
		color: var(--button-dark-blue-color-hover);
		border-color: var(--button-dark-blue-border-color-hover);

		&:hover {
			background-color: var(--button-dark-blue-bg-hover);
			color: var(--button-dark-blue-color-hover);
		}
	}

	&--active {
		background-color: var(--button-dark-blue-bg-hover);
		color: var(--button-dark-blue-color-hover);
		border-color: var(--button-dark-blue-border-color-hover);
	}

	// Light Button
	&[color='light'] {
		// Colors
		background-color: var(--button-light-bg);
		border-color: var(--button-light-border-color);
		color: var(--button-light-color);

		// Hover and Focus
		&:hover,
		&:focus {
			background-color: var(--button-light-bg-hover);
			color: var(--button-light-color-hover);
			border-color: var(--button-light-border-color-hover);
		}

		&:disabled,
		&[disabled] {
			cursor: not-allowed;
			background-color: var(--button-light-bg-disabled);
			color: var(--button-light-color-disabled);
			border-color: var(--button-light-border-color-disabled);

			&:hover {
				background-color: var(--button-light-bg-disabled);
				color: var(--button-light-color-disabled);
			}
		}

		&.Button--loading {
			cursor: progress;
			background-color: var(--button-light-bg-hover);
			color: var(--button-light-color-hover);
			border-color: var(--button-light-border-color-hover);

			&:hover {
				background-color: var(--button-light-bg-hover);
				color: var(--button-light-color-hover);
			}
		}

		&.Button--active {
			background-color: var(--button-light-bg-hover);
			color: var(--button-light-color-hover);
			border-color: var(--button-light-border-color-hover);
		}
	}

	// Small
	&--sm {
		font-size: var(--button-sm-font-size);
		font-weight: 600;
		border-radius: var(--button-sm-border-radius);
		height: var(--button-sm-height);
		padding: var(--button-sm-padding);
		gap: 8px;

		.MaterialSymbol {
			font-size: var(--button-sm-icon-size);
		}
	}
}