.FileManager {
	display: grid;
	grid-template-rows: 80px 1fr;
	width: 100%;
	height: 100%;
	border: 1px #ccc solid;
	background-color: #fff;
	font-size: 14px;
	position: relative;

	& * {
		box-sizing: border-box;
	}

	&_Header {
		display: grid;
		align-items: center;
		border-bottom: 1px #ccc solid;
		padding: 10px;

		&_Bar {
			display: grid;
			align-items: center;
			grid-auto-flow: column;
			justify-content: space-between;

			h1 {
				padding: 0;
				margin: 0;
			}

			.Icon {
				cursor: pointer;
				font-size: 34px;

				&:hover {
					color: #ff3974;
				}
			}
		}

		&_Directory {
			display: grid;
			grid-auto-flow: column;
			align-items: center;
			justify-content: left;

			i {
				margin-right: 5px;
			}
		}
	}

	&_Body {
		display: grid;
		grid-template-columns: 200px 1fr;
		overflow: hidden;

		&_List {
			display: grid;
			max-width: 200px;
			grid-template-rows: 40px 1fr;
			border-right: 1px #ccc solid;
			overflow: hidden;

			& > ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
				overflow: auto;
				overflow-x: hidden;
				max-height: 100%;
				background-color: #fff;

				& > li {
					padding: 5px;
					cursor: pointer;
					transition: all 0.2s ease-in-out;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

					&:hover {
						background-color: #eee;
					}

					&.FileManager_File--selected {
						background-color: #ffd104;
					}
				}
			}
		}

		&_Nav {
			display: grid;
			grid-template-columns: auto 30px 30px;
			align-items: center;
			background-color: #ccc;
			padding: 10px;
			border-right: 1px #fff solid;

			&_Back {
				div {
					cursor: pointer;
					i {
						margin-right: 5px;
					}
				}
			}

			&_NewFolder {
				cursor: pointer;
				text-align: right;
			}

			&_Upload {
				cursor: pointer;
				text-align: right;
			}
		}

		&_Preview {
			display: grid;
			grid-template-rows: 40px 100%;
			max-height: 100%;
			overflow: hidden;
		}

		&_Info {
			display: grid;
			justify-content: space-between;
			grid-auto-flow: column;
			justify-items: center;
			align-items: center;
			background-color: #ccc;
			padding: 10px;
		}

		&_Item {
			overflow: auto;
			overflow-x: hidden;
			max-height: 100%;
			display: grid;
			align-items: center;
			justify-content: center;

			img,
			video {
				width: 100%;
				height: auto;
				max-width: 95%;
				max-height: 95%;
			}
		}
	}

	&_Folder {
		& > i {
			color: #ffd104;
		}
	}

	&_ContextMenu {
		position: absolute;
		background-color: #fff;
		border: 1px solid #ccc;
		z-index: 99;
		padding: 5px;

		& > ul {
			list-style-type: none;
			margin: 0;
			padding: 0;

			& li {
				display: grid;
				grid-template-columns: 20px 1fr;
				gap: 5px;
				padding: 3px;
				cursor: pointer;
				transition: all 0.2s ease-in-out;
				align-items: center;

				i {
					text-align: center;
				}

				&:hover {
					background-color: #eee;
					color: #ff0000;
				}
			}

			.FileManager_ContextMenu_Import {
				&:hover {
					background-color: #eee;
					color: #009207;
				}
			}
		}
	}

	&_Dialog {
		display: grid;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		background-color: hsla(0, 0%, 24%, 0.9);
		align-content: center;
		justify-content: center;

		&_Content {
			width: 400px;
			padding: 10px;
			background-color: #fff;
			border: 1px solid #ccc;
		}
	}
}
