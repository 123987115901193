.Statistics {

	&_Table {
		border: 2px solid #FFFFFF;
		width: 100%;
		text-align: center;
		border-collapse: collapse;
		width: 100%;
		border-radius: 8px;

		thead {
			tr {
				color: var(--link-color-hover);
				background-color: #f2c6d3;

				th {
					font-weight: 600;
					padding: 10px;
				}
			}
		}

		tbody {
			tr {
				background-color: #fff;

				td {
					padding: 10px;
				}
			}
		}
	}
}