// React Confirm Alert overrides
.react-confirm-alert-overlay {
	background-color: rgba(255, 56, 116, 0.3) !important;

	.react-confirm-alert-body {
		color: var(--primary-color);
		font-size: var(--default-font-size);
		font-weight: 500;
		line-height: 26px;
		width: 1200px;
		max-width: 90vw;

		h1 {
			color: var(--highlight-color);
		}
	}

	.react-confirm-alert-button-group {
		button {
			display: inline-grid;
			grid-auto-flow: column;
			gap: 10px;
			border-radius: var(--form-button-border-radius);
			border: var(--form-element-border);
			padding: 18px 24px;
			align-items: center;
			text-decoration: none;
			font-size: 16px;
			font-weight: 500;
			background-color: #fff;
			color: var(--primary-color);

			&:hover {
				text-decoration: none;
				border: 1px solid var(--highlight-color);
				color: var(--highlight-color);
			}

			&:focus {
				outline: 0;
			}

			&.button--info {
				background-color: var(--info-light);
				color: var(--primary-color);

				&:hover {
					background-color: var(--info-color);
				}
			}

			&.button--success {
				background-color: var(--success-light);
				color: #fff;

				&:hover {
					background-color: var(--success-color);
				}
			}

			&.button--danger {
				background-color: var(--error-light);
				color: #fff;

				&:hover {
					background-color: var(--error-color);
				}
			}

			&.button--warning {
				background-color: var(--warning-light);
				color: var(--primary-color);

				&:hover {
					background-color: var(--warning-color);
				}
			}
		}
	}
}