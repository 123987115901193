@import '../../../assets/mixins';

.H2 {
	font-weight: 600;
	font-size: 26px;
	line-height: 36.4px;
	color: var(--primary-color);

	@include minDesktop {
		font-size: 32px;
		line-height: 44.8px;
	}

	&--centered {
		text-align: center;
	}
}
