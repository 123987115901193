.AddArticle {
	padding: 20px;

	&_Form {
		margin-top: 20px;
		max-width: 1024px;
	}

	&_TopBar {
		display: grid;
		grid-auto-flow: column;
		justify-content: right;
		margin-top: 20px;
		max-width: 1024px;
	}
}
