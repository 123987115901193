.NavigationLink {
	color: #000;

	&:hover {
		color: blue;
		text-decoration: underline;
	}

	&--pending {
		color: yellow;
	}

	&--active {
		color: red;
	}
}
