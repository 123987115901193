.ImageCropper {
	padding-bottom: 60px;

	&_Preview {
		display: grid;
		grid-auto-flow: row;
		gap: 20px;
	}

	&_SelectFile {
		background-color: #fff;
		border-radius: 10px;
		padding: 10px;
		margin-bottom: 20px;
	}

	&_EditorContainer {
		display: grid;
		grid-auto-flow: row;
		gap: 20px;
		background-color: #fff;
		border-radius: 10px;
		padding: 10px;

		h3 {
			margin: 0;
			padding: 0;
		}
	}

	&_Buttons {
		display: grid;
		grid-auto-flow: column;
		gap: 20px;
		justify-content: right;
	}

	&_EditorTools {
		display: grid;
		grid-auto-flow: column;
		gap: 20px;
		justify-content: space-between;
	}

	&_EditorArea {
		position: relative;
	}

	&_Error {
		color: var(--error-color);
		padding: 0 10px;
	}
}
