.ReactLink {
	text-decoration: none;
	cursor: pointer;

	&:hover {
		color: var(--highlight-color);
	}

	&--icon {
		display: inline-grid;
		grid-auto-flow: column;
		gap: 10px;
		align-items: center;
		align-content: center;
	}

	&--blank {
		&::after {
			content: '\e89e';
			font-family: var(--default-Icon-font);
			font-weight: 900;
			margin-left: 10px;
		}
	}
}
