@import '../../../assets/mixins.scss';

// TextArea Container
.TextArea {
	display: grid;

	&_Label {
		display: grid;
		grid-auto-flow: column;
		font-size: 18px;
		margin-bottom: 10px;
		gap: 6px;
		justify-items: start;
		justify-content: start;
		align-items: center;

		.MaterialSymbol {
			color: var(--input-icon-color);
			font-size: 18px;
			line-height: 18px;
		}

		label {
			color: var(--primary-color);
			white-space: nowrap;
			font-size: 16px;
			font-weight: 400;
		}

		&_Help {
			cursor: help;
			justify-self: end;

			&:hover {
				color: var(--highlight-color);
			}
		}
	}


	&_Wrapper {
		display: grid;
		position: relative;

		textarea {
			border-radius: var(--input-border-radius);
			box-shadow: var(--input-box-shadow);
			color: var(--primary-color);
			background-color: var(--input-background);
			width: 100%;
			resize: none;

			// Medium - Defaults
			// height: var(--input-height);
			font-size: var(--input-font-size);
			padding: var(--input-padding);
			border: var(--input-border-size) solid var(--input-border-color);

			&:focus {
				outline: var(--input-border-size) solid var(--highlight-color);
				outline-offset: 1px;
				border: none;
			}

			&::placeholder {
				color: var(--input-placeholder-color);
				opacity: 1;
			}
		}
	}

	// Invalid Message Feedback
	&_InvalidMessage {
		display: grid;
		grid-template-columns: min-content auto;
		color: var(--error-color);
		margin: 8px 0 0 0;
		font-weight: 500;
		align-items: center;
		gap: 8px;
		font-size: var(--input-error-font-size);

		.MaterialSymbol {
			font-size: var(--input-error-font-size);
		}
	}

	// --- States ---

	// Resize all directions
	&--auto {
		.TextArea_Wrapper textarea {
			resize: auto;
		}
	}
	// Resize vertical only
	&--vertical {
		.TextArea_Wrapper textarea {
			resize: vertical;
		}
	}

	// Resize horizontal only
	&--horizontal {
		.TextArea_Wrapper textarea {
			resize: horizontal;
		}
	}

	// --- States ---

	// Warning
	&--warning {
		.MaterialSymbol,
		label {
			color: var(--warning-color);
		}

		.TextArea_Wrapper {

			input {
				border-color: var(--warning-color);
				color: var(--warning-color);

				&::placeholder {
					color: var(--warning-color);
				}
			}
		}
	}

	// Invalid
	&--invalid {
		.MaterialSymbol,
		label {
			color: var(--error-color);
		}

		.TextArea_Wrapper {
			input {
				border-color: var(--error-color);
				color: var(--error-color);

				&::placeholder {
					color: var(--error-color);
				}
			}
		}
	}

	// Disabled
	&--disabled {
		.TextArea_Wrapper input {
			background-color: #f2f2f2;
			color: #9b9b9b;
		}
	}

	// --- Browser specific fixes ---

	// Remove chrome indicator icons
	.TextArea_Wrapper textarea[type]::-webkit-calendar-picker-indicator {
		background: none;
		display: none;
	}
}
