.EditArticleTranslations {
	padding: 20px;
	font-size: 1em;
	max-width: 1024px;

	.Admin_H1 {
		margin-bottom: 20px;
	}

	.BackLink {
		margin-bottom: 20px;
	}

	.TabLinkNavigation {
		margin-top: 20px;
	}

	.ProgressBar {
		margin-bottom: 20px;
	}

	&_WarningZone {
		border-color: var(--warning-color);
		display: grid;
		grid-auto-flow: row;
		gap: 14px;

		&_Buttons {
			display: grid;
			grid-auto-flow: column;
			gap: 8px;
			justify-content: left;
			color: #000;
		}
	}

	&_Flag {
		display: block;
		width: 27px;
		height: 20px;
	}

	&_Language {
		a {
			color: var(--highlight-color);
			text-decoration: underline;
		}
	}

	&_LanguageTable {
		width: 100%;
		text-align: left;
		margin-bottom: 20px;

		thead {
			tr {
				th {
					font-weight: bold;
					padding-bottom: 10px;
				}
			}
		}

		tbody {
			tr {
				&:nth-child(even) {
					background-color: #f5f5f5;
				}

				&:nth-child(odd) {
					background-color: #f1f1f1;
				}

				td {
					padding: 5px;
				}
			}
		}

		&_Status {
			color: #9b9b9b;
			font-size: 1.5em;
		}

		&_Audio {
			color: #757575;
			font-size: 1em;
		}

		&_Delete {
			color: var(--error-color);
			font-size: 1em;
			text-align: center;

			i {
				cursor: pointer;
			}
		}

		&--translated {
			.EditArticleTranslations_LanguageTable_Status {
				color: var(--success-color);
			}
		}

		&--error {
			.EditArticleTranslations_LanguageTable_Status {
				color: var(--error-color);
			}
		}

		&_Actions {
			display: grid;
			grid-auto-flow: column;
			gap: 8px;
			justify-content: left;
			color: #000;
		}
	}
}
