.Admin {

	&_Modal {
		background-color: rgba(0, 0, 0, 0.5);
		display: grid;
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 99;
		padding: 50px;

		&_Content {}
	}

}