.Pagination {
	// Next-Preview links container
	&_NextPrev {
		display: grid;
		grid-auto-flow: column;
		align-content: space-around;
		justify-content: space-between;
		padding: 20px 0;

		& > a,
		span {
			display: inline-block;
			padding: 18px 24px;
			font-size: 16px;
			text-decoration: none;
			transition: all 0.2s ease-in-out;
			border: 1px solid rgba(0, 0, 0, 0.2);
			border-radius: 100px;
			color: #0f3652;

			&:hover {
				border-color: #ff3974;
				color: #ff3974;
			}

			&:active {
				border-color: #ff3974;
				color: #ff3974;
			}
		}

		// Preview link
		&_Prev {
			justify-self: left;
		}

		&_Next {
			justify-self: right;
		}
	}

	// Pagination links container
	&_Links {
		display: grid;
		grid-auto-flow: column;
		align-content: center;
		justify-content: center;
		gap: 6px;

		&_Link {
			display: grid;
			color: #0f3652;
			padding: 18px 24px;
			font-size: 16px;
			font-weight: 600;
			text-decoration: none;
			transition: all 0.2s ease-in-out;
			text-align: center;
			align-items: center;
			justify-content: center;

			&:hover {
				color: #ff3974;
			}

			&:active {
				color: #ff3974;
			}
		}

		// Active link
		&_Active {
			color: #ff3974;
		}
	}

	// Disabled state
	.Link--disabled {
		color: #000;
		pointer-events: none;
		opacity: 0.5;

		&:hover {
			color: #000;
			text-decoration: none;
		}
	}

	// Active state
	.Link--active {
		color: #ff3974;
		pointer-events: none;
		opacity: unset;

		&:hover {
			color: #ff3974;
			text-decoration: none;
		}
	}
}
